<template>
  <div class="main">
    <div class="top">
      <div class="qrcode-area">
        <img class="qrcode-img" :src="currQrcode">
        <div class="qrcode-mask" v-if="isShowMsg">
          <div class="msg">{{ msg }}</div>
          <div class="time">{{ time }}</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="button-container">

        <div class="button-wrap" v-if="status == 0 || status == 4" @click="refreshClick">
          <img :class="{'rotate-image': rotate}" src="../assets/refresh.png">
        </div>

        <div class="button-wrap" v-if="status == 1">
          <p>{{ countdown }}</p>
        </div>

        <div class="button-wrap" v-if="status == 2">
          <img src="../assets/done.png">
        </div>

      </div>
      <div class="content">
        <div class="title">游泳馆次卡<br>二维码</div>
        <div class="desc">到闸机的时候，点击上面刷新按钮</div>
      </div>
    </div>
  </div>
</template>

<script>
// import get from '@/api/keys-api'
import axios from "axios";
// import {useRoute} from "vue-router";

const baseUrl = process.env.VUE_APP_API_BASE_URL

export default {
  name: "QrCode",
  data() {
    return {
      rotate: false,
      currQrcode: '',
      defaultQrcode: require('../assets/qrcode.png'),
      status: 0, // 0=未刷新；1=倒计时；2=已使用

      msg: '',
      time: '',

      countdown: 10,

      timer: null,
      key: '',

      isShowMsg: false,
    }
  },
  // watch: {
  //   "$route.query": {
  //     handler() {
  //       if (this.$route.query) {
  //         console.log(this.$route.query)
  //         // this.departmentDid 是在data中定义的
  //         // this.departmentDid = this.$route.query.did
  //       }
  //       return
  //     },
  //     immediate: true
  //   }
  // },

  computed: {},
  created() {
    this.key = this.$route.params.key
    this.defaultStatus()
    this.checkUsed(this.key)
  },
  methods: {
    showMsg(msg = '', time = '') {
      this.msg = msg
      this.time = time
      this.isShowMsg = true
    },

    hideMsg() {
      this.isShowMsg = false
    },

    defaultStatus() {
      this.changeStatus(0)
    },

    changeStatus(status, time = '') {
      this.status = status

      if (status == 0) {
        this.currQrcode = this.defaultQrcode
        this.showMsg('请刷新')
      }

      if (status == 1) {
        this.hideMsg()
      }

      if (status == 2) {
        this.currQrcode = this.defaultQrcode
        this.showMsg('已使用', time)
      }

    },

    async getByKey(key, check = false) {
      // let res = await get.get(key, check)

      const requestConfig = {

        headers: {
          'Content-Type': 'application/json',
        },

        transformRequest: [(data) => {
          this.rotate = true
          return JSON.stringify(data);
        }],

        transformResponse: [(data) => {
          this.rotate = false
          return JSON.parse(data);
        }],

      };

      return new Promise((resolve, reject) => {
        axios.post(`${baseUrl}/keys/get`, {
          key: key,
          check: check,
        }, requestConfig).then(res => {
          this.rotate = false
          resolve(res)
        }).catch(err => {
          this.rotate = false
          this.changeStatus(0)
          this.showMsg(err.message)
          reject(err)
        })
      })
    },

    async refreshClick() {
      let key = this.key
      let response
      try {
        response = await this.getByKey(key)
      } catch (err) {
        return
      }
      let res = response.data

      if (!res.success) {
        this.msg = '⚠ ' + res.message
        return;
      }

      if (res.data.used) {
        this.changeStatus(2, res.data.useTime)
      } else {
        this.currQrcode = res.data.qrcode
        this.countdown = res.data.countdown
        this.changeStatus(1)

        // 如果有定时器，则先清理
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.timer = setInterval(() => {
          if (this.countdown == 1) {
            this.changeStatus(4)
            // this.countdown = '...'

            this.checkUsed(key)

            clearInterval(this.timer)
            return
          }
          this.countdown -= 1
        }, 1000)
      }
    },

    async checkUsed(key) {
      let response;
      try {
        response = await this.getByKey(key, true)
      } catch (e) {
        return
      }

      let res = response.data
      if (!res.success) {
        this.msg = '⚠ ' + res.message
        return;
      }

      if (res.data.used) {
        this.changeStatus(2, res.data.useTime)
      } else {
        this.changeStatus(0)
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* 定义旋转动画 */
@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 设置图片样式，添加旋转动画 */
.rotate-image {
  //width: 100px; /* 根据实际情况设置图片宽度 */
  //height: 100px; /* 根据实际情况设置图片高度 */
  //transition: transform 0.3s ease-in-out;

  animation: rotateAnimation 2s linear infinite;
}

/* 设置鼠标悬停时的旋转效果 */
//.rotate-image:hover {
//  animation: rotateAnimation 2s linear infinite;
//}


.main {
  width: 375px;
  //height: 812px;
  background: #d3b398;
  //border-radius: 20px;
  .top {
    //width: 100%;
    height: 492px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    //.qrcode-container {
    //  width: 338px;
    //  height: 398px;
    //  background: radial-gradient(#ffffff, rgba(255,255,255,0.00));
    //  border-radius: 50%;
    //}

    .qrcode-area {
      width: 279px;
      height: 279px;
      position: relative;
      background: radial-gradient(#ffffff, rgba(255, 255, 255, 0.0));

      .qrcode-img {
        //top: 0;
        //bottom: 0;
        //left: 0;
        //right: 0;
        width: 279px;
        height: 279px;
        box-shadow: 0 0 15px #e3cbb7;
      }

      .qrcode-mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.90);
        line-height: 33px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .msg {
          font-size: 28px;
          font-family: Montserrat, Montserrat-700;
          font-weight: 700;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.50);
        }

        .time {
          margin-top: 15px;
          //width: 279px;
          //height: 24px;
          //background: rgba(0,0,0,0.50);
          font-size: 20px;
          font-family: Montserrat, Montserrat-700;
          font-weight: 700;
          text-align: CENTER;
          color: rgba(0, 0, 0, 0.50);
          line-height: 23px;
        }
      }
    }
  }

  .bottom {
    width: 375px;
    height: 320px;
    background: #ffffff;
    position: absolute;
    top: 492px;
    border-radius: 20% 20% 0 0;
    //overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    //.button-container:active {
    //  opacity: 0.5;
    //}

    .button-container {
      top: -40px;
      left: 150px;
      position: absolute;
      width: 86px;
      height: 86px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 4px 30px 0px rgba(80, 89, 98, 0.10);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .button-wrap {
        width: 45px;
        height: 45px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        font-family: Montserrat, Montserrat-700;
        font-weight: 700;
        text-align: CENTER;
        color: #bfc2c8;
        img {
          width: 45px;
        }
      }
    }

    .content {
      margin-top: 114px;
      //margin-top: 80px;

      //background-color: #2c3e50;
      width: 327px;
      display: flex;
      flex-direction: column;

      //align-content: center;

      .title {
        height: 68px;
        font-size: 28px;
        font-family: Montserrat, Montserrat-700;
        font-weight: 700;
        text-align: CENTER;
        color: #000000;
        line-height: 33px;
      }

      .desc {
        margin-top: 16px;
        height: 22px;
        font-size: 16px;
        font-family: Montserrat, Montserrat-500;
        font-weight: 500;
        text-align: CENTER;
        color: rgba(0, 0, 0, 0.50);
        line-height: 22px;
      }
    }

  }
}
</style>