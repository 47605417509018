<template>
  <QrCode></QrCode>
</template>

<script>
import QrCode from "@/components/QrCode.vue";

export default {
  name: 'QrCodeView',
  components: {
    QrCode,
  },
}
</script>

<style lang="scss">
#app {
  background: #d3b398;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
